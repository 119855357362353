import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "myModal",
  class: "modal"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "create-new-group" }
const _hoisted_4 = ["placeholder", "maxlength"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "margin-top-10 color-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          ref: "groupName",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localGroup.Name) = $event)),
          type: "text",
          placeholder: _ctx.$t('Name'),
          maxlength: _ctx.maxLength,
          class: _normalizeClass(["form-control", {'border-red': _ctx.anyError}])
        }, null, 10, _hoisted_4), [
          [_vModelText, _ctx.localGroup.Name]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["display-flex", {'justify-content-end': _ctx.isMobileView, 'margin-top-10': _ctx.isMobileView}])
        }, [
          _createElementVNode("button", {
            class: "btn btn-secondary margin-left-20",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CancelGroupCreation()))
          }, _toDisplayString(_ctx.$t('CancelButton')), 1),
          _createElementVNode("button", {
            class: "btn btn-primary margin-left-20",
            disabled: _ctx.isCreateNewGroupBtnDisabled,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CreateGroup()))
          }, _toDisplayString(_ctx.$t('SaveButton')), 9, _hoisted_5)
        ], 2)
      ]),
      (_ctx.anyError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}