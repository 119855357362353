import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "item-listing" }
const _hoisted_2 = {
  key: 1,
  class: "empty-div"
}
const _hoisted_3 = { class: "no-item-parent-div" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "items-div"
}
const _hoisted_6 = ["index"]
const _hoisted_7 = ["onMouseover"]
const _hoisted_8 = { class: "main-body-container inline-flex100" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "img-select" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "item-position-from-image valign-parent50 width85" }
const _hoisted_14 = { class: "group-selection-question-title" }
const _hoisted_15 = {
  key: 0,
  class: "time font-size-12 margin-top-5"
}
const _hoisted_16 = { class: "font-weight-600" }
const _hoisted_17 = {
  key: 1,
  class: "font-size-12 color-red font-weight-bold margin-top-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('DeleteFolder'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.localGroupSelectionQuestions === null || _ctx.localGroupSelectionQuestions === undefined || _ctx.localGroupSelectionQuestions.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createElementVNode("label", {
                class: "font-weight-100 font-size-30",
                innerHTML: _ctx.$t('Participants.GroupSelection.NoItemMessage')
              }, null, 8, _hoisted_4)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_draggable, {
            list: _ctx.localGroupSelectionQuestions,
            tag: "ul",
            class: "list-group",
            handle: ".handle",
            animation: "500",
            onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
            onEnd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
          }, {
            default: _withCtx(() => [
              _createVNode(_TransitionGroup, { type: "transition" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGroupSelectionQuestions, (data, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: data.Id,
                      index: index,
                      style: _normalizeStyle([data.IsDisabled ? { 'pointer-events':'none','opacity':'0.4' } : ''])
                    }, [
                      _createElementVNode("div", {
                        class: "main-item-div",
                        style: _normalizeStyle({
                'background-color': _ctx.SetBackgroundColor(data.Id),
              }),
                        onMouseover: ($event: any) => (_ctx.ShowDragAndDropIcon(index)),
                        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowDragAndDropIcon(null)))
                      }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            class: "group-selecion-list-item ipad_tag",
                            onClick: ($event: any) => (_ctx.SelectGroupSelectionItem(data))
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["valign-parent50 cursor-move handle drag-drop-icon", {'visibility-hidden': _ctx.hoverItemIndex !== index}])
                            }, [
                              _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_10)
                            ], 2),
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("img", { src: _ctx.groupSelectonIcon }, null, 8, _hoisted_12)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", {
                                class: "display-flex flex-direction-column valign",
                                style: _normalizeStyle('color:' + _ctx.SetSelectedItemFontColor(data.Id, false))
                              }, [
                                _createElementVNode("span", _hoisted_14, _toDisplayString(data.Title), 1),
                                (data.IsPublished)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("PublishedWithHyphen")), 1),
                                      _createElementVNode("span", {
                                        class: "time publish-time",
                                        style: _normalizeStyle('color:' + _ctx.SetSelectedItemFontColor(data.Id, true))
                                      }, _toDisplayString(_ctx.FormatDateTime(data)), 5)
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("Dialogue.Unpublished")), 1))
                              ], 4)
                            ])
                          ], 8, _hoisted_9)
                        ])
                      ], 44, _hoisted_7)
                    ], 12, _hoisted_6))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["list"])
        ]))
  ]))
}